/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
    find, get, isEmpty, map, difference, forEach,
    filter, includes, size, head, cloneDeep, toNumber,
    pick,
    replace,
} from 'lodash';
import { Close } from '@material-ui/icons';
import {
    setCart, setBookingFlowPreviousStep,
    setAvailableTherapists, setAvailableTherapistsWithTimeSlots, setAnonymousCart,
    setField,
} from '../../../Actions';
import CTAButton from '../../Shared/CTAButton';
import '../Assets/Styles/index.css';
import './Assets/Styles/ModalityStep.css';
import { addCartProduct, deleteCartProduct, guestCreateCart, updateCartProduct } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { defaultErrorMessage, getB2bInfo } from '../../../constants';
import { seBookingDetails } from '../../Shared/WebAnalytics';
import Select from 'react-select';
import ErrorModal from '../../Shared/ErrorModal';
import SelectTimeWrapper from '../../Shared/SelectTimeWrapper';
import moment from 'moment';
import { calculateEventProviders } from '../../Shared/Helpers';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import PhoneInput from '../../Shared/PhoneInput';
import EmailInput from '../../Shared/EmailInput';
import { contactRepresentative } from '../../Shared/Helpers';
import BlueCheckLottie from '../../Shared/BlueCheckLottie';
import LoadingOverlay from '../../Shared/LoadingOverlay';

class ModalityStepForEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ctaDisabled: true,
            optionIds: [get(this.props, 'booking.currentOption.id', '')],
            submitted: false,
            gender: '',
            session: 0,
            option_id: null,
            // TODO REVISIT values
            price: '',
            memberPrice: '',
            raw_price: 0,
            raw_member_price: 0,
            unavailablePros: false,
            unavailableProsMessage: '',
            showErrorModal: false,
            error: '',
            duration: 0,
            session_date: get(props, 'fieldsHolder.listingPageStorage.cart.session_date', '') || moment(get(props, 'booking.cart.date.utc', '')).format('YYYY-MM-DD'),
            start_time: props?.booking?.cart?.date ? get(props, 'booking.cart.time.display', '') : "8:00 AM",
            end_time: props?.booking?.cart?.date?.cartProducts?.length ? moment(get(props, 'booking.cart.cartProducts.0.session_end_time', '')).format("h:mm A") : "11:00 AM",
            guest_no: Number(get(props, 'fieldsHolder.listingPageStorage.cart.guest_no', 0)),
            endTimeIsInvalid: "",
            number_of_providers: props?.booking?.cart?.id ? get(props, "booking.cart.no_pros", 0) : 0,
            break_time: 0,
            recommendation_recieved: false,
            showRequestRepModal: false,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            emailIsValid: false,
            phoneIsValid: false,
            showSuccessModal: false,
            loading: false,
        };
        this.listAppointmentsByClient = this.listAppointmentsByClient.bind(this);
        this.submitOptionsAndNext = this.submitOptionsAndNext.bind(this);
        this.updateOptionsByCartProduct = this.updateOptionsByCartProduct.bind(this);
        this.updateCTAStatus = this.updateCTAStatus.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
        this.showRelevantPrices = this.showRelevantPrices.bind(this);
        this.isMassageType = this.isMassageType.bind(this);
        this.getModalityTitle = this.getModalityTitle.bind(this);
        this.getDuration = this.getDuration.bind(this);
        this.getGender = this.getGender.bind(this);
        this.formatTitle = this.formatTitle.bind(this);
        this.formatSubtitle = this.formatSubtitle.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.setDefaultValues = this.setDefaultValues.bind(this);
        this.showRelevantPricesOldWay = this.showRelevantPricesOldWay.bind(this);
        this.createEventGuestCart = this.createEventGuestCart.bind(this);
        this.relevantBody = this.relevantBody.bind(this);
        this.existantCartFlow = this.existantCartFlow.bind(this);
        this.isValidTime = this.isValidTime.bind(this);
        this.requestRepModal = this.requestRepModal.bind(this);
        this.continueFlowWithRepresentative = this.continueFlowWithRepresentative.bind(this);
        this.thankYouView = this.thankYouView.bind(this);
        this.requestHelpBtn = this.requestHelpBtn.bind(this);

    }
    // TODO
    // error modal inside drawer
    // skipping add when error is returned for modality

    componentDidMount() {
        this.setDefaultValues();
    }

    setDefaultValues() {
        const { end_time, start_time, session_date } = this.state;
        this.setState({ end_time: end_time ? end_time : moment(`${session_date} ${start_time}`, "YYYY-MM-DD h:mm A").add(3, 'hours').format("h:mm A") }, () => {
            this.setState({ endTimeIsInvalid: this.isValidTime() ? "invalid" : "" })
        })
        const tempArr = cloneDeep(this.state.optionIds);
        if (includes(get(this.props, 'booking.product.slug', ''), 'massage')) {
            const opts = filter(get(this.props, 'booking.product.product_options', []), (ci) => (ci.display_type === 'bubble'));
            if (!isEmpty(opts)) {
                forEach(opts, (optEl) => {
                    const defaultOption = find(get(optEl, 'options', []), (opp) => (opp.default));
                    if (defaultOption) {
                        tempArr.push(get(defaultOption, 'id', ''));
                        if (includes(defaultOption.slug, 'length')) {
                            this.setState({ option_id: get(defaultOption, 'id', null) });
                        }
                    }
                });
                this.setState({ optionIds: tempArr });
            }
        } else {
            const {
                raw_member_price, raw_price, price, memberPrice,
            } = get(this.props, 'booking.currentOption', {});
            this.setState({
                raw_member_price, raw_price, price, memberPrice,
            });
        }
        this.updateCTAStatus(true);
    }
    isMassageType() {
        return get(this.props, 'product.slug', '') === 'massage';
    }

    getModalityTitle() {
        return get(this.props, 'booking.currentOption.title');
    }

    getDuration() {
        if (this.isMassageType()) {
            return get(this.state, 'session', '');
        }
        return '';
    }

    getGender() {
        if (this.isMassageType()) {
            return get(this.state, 'gender', '');
        }
        return '';
    }

    formatTitle() {
        const title = get(this.props, 'booking.currentOption.title', '');
        if (this.isMassageType()) {
            return `${this.getDuration()} ${title}`;
        }
        return title;
    }

    formatSubtitle() {
        if (this.isMassageType() && this.getGender()) {
            return `with a ${this.getGender()} Provider`;
        }
        return '';
    }

    getPrice() {
        const opt = get(this.state, 'product.opt', null);
        if (this.isMassageType()) {
            const sessions = get(this.state, 'product.sessions', []);
            const lngOpt = find(sessions, (op) => (get(this.state, 'options.sessions', '') === op.service_id));
            const total = get(lngOpt, 'rawPrice', '') ? Number(get(lngOpt, 'rawPrice', '')) + Number(getB2bInfo('parking_fee')) : 0;
            return `${get(lngOpt, 'currency', '')}${total || ''}`;
        }
        const subT = get(opt, 'rawPrice', '') ? Number(get(opt, 'rawPrice', '')) + Number(getB2bInfo('parking_fee')) : '';
        return `${get(opt, 'currency', '')}${subT || ''}`;
    }
    existantCartFlow() {
        // TODO figure out how to update guest no and times..
        const cartId = get(this.props, 'booking.cart.id', ''),
            cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', ''),
            currentProductId = get(this.props, 'booking.cart.cartProducts.0.product_id', ''),
            newProductId = get(this.props, 'product.id', '');
        if (Number(currentProductId) === Number(newProductId)) {
            updateCartProduct(cartId, cartProductId, {
                option_ids: this.state.optionIds,
            }, get(this.props, 'fieldsHolder.csrfToken', ''), (response2) => {
                this.props.assignToCart({ cart: decode(response2.data.cart) });
                const cp = get(this.props, `booking.cart.cartProducts.0`, {});
                seBookingDetails(decode(response2.data.cart), cp, get(this.props, 'product.title', '').toLowerCase(), this.props.client.first_time_booker, 'booking_23_variant', 1, get(cp, 'subtitle', ''), get(this.props, 'client.loggedIn', false), get(this.props, 'booking.cart.address.market', ''), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
                this.props.setLoaderFlag(false);
                this.props.next();
                this.props.closeDrawer();
            }, (err) => this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
        } else {
            deleteCartProduct(cartId, cartProductId, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
                this.props.assignToCart({ cart: decode(response.data.cart) });
                addCartProduct(cartId, {
                    product_id: get(this.props, 'product.id', ''),
                }, (respp) => {
                    this.props.assignToCart({ cart: decode(respp.data.cart) });
                    const relevantCartProduct = get(this.props, 'booking.cart.cartProducts.0', null);
                    updateCartProduct(cartId, get(relevantCartProduct, 'id', ''), {
                        option_ids: this.state.optionIds,
                    }, get(this.props, 'fieldsHolder.csrfToken', ''), (response2) => {
                        this.props.assignToCart({ cart: decode(response2.data.cart) });
                        const cp = get(this.props, `booking.cart.cartProducts.0`, {});
                        seBookingDetails(decode(response2.data.cart), cp, get(this.props, 'product.title', '').toLowerCase(), this.props.client.first_time_booker, 'booking_23_variant', 1, get(cp, 'subtitle', ''), get(this.props, 'client.loggedIn', false), get(this.props, 'booking.cart.address.market', ''), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
                        this.props.setLoaderFlag(false);
                        this.props.next();
                        this.props.closeDrawer();
                    }, (err) => this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
                }, (err) => this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
            }, (err) => this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
        }
    }
    createEventGuestCart() {
        // TODO might need support for editting new options?
        let { address, cart, user_kind } = get(this.props, 'fieldsHolder.listingPageStorage', {});
        const cartId = get(this.props, 'booking.cart.id', ''),
            cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', '');
        const { session_date, start_time, end_time, guest_no, number_of_providers } = this.state;
        cart["session_time"] = moment(`${session_date} ${start_time}`, "YYYY-MM-DD h:mm A").format("HH:mm")
        cart["session_end_time"] = moment(`${session_date} ${end_time}`, "YYYY-MM-DD h:mm A").format("HH:mm")
        cart["guest_no"] = guest_no;
        cart["no_pros"] = number_of_providers;
        this.props.setLoaderFlag(true);
        if (cartId && cartProductId && size(get(this.props, 'booking.cart.cartProducts', [])) == 1) {
            this.existantCartFlow();
        } else {
            guestCreateCart({
                ...cart,
                user_kind,
                product_id: get(this.props, 'product.id', ''),
                cart_products: [{
                    option_ids: this.state.optionIds,
                }],
            }, address,
                get(this.props, 'fieldsHolder.csrfToken', ''),
                (response) => {
                    this.props.assignToCart({ cart: decode(response.data.cart) });
                    this.props.setLoaderFlag(false);
                    this.props.next();
                    this.props.closeDrawer();
                },
                (err) => {
                    this.props.setLoaderFlag(false);
                    const { code, message } = get(err, 'response.data.errors.0', {});
                    if (code === "unavailable_providers") {
                        this.setState({ unavailablePros: true, unavailableProsMessage: message });
                        return;
                    }
                    this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage));
                },
            );
        }
    }
    submitOptionsAndNext(skipNext = false) {
        if (!skipNext) {
            this.props.setLoaderFlag(true);
            this.setState({ submitted: true });
        }
        const {
            optionIds, price,
        } = this.state;
        const loggedIn = get(this.props, 'client.loggedIn', false);
        const anonCart = get(this.props, 'anonymousCart', {});
        let cps = get(anonCart, 'cartProducts', []);
        const market = get(this.props, 'anonymousCart.address.market', '') || get(this.props, 'booking.cart.address.market', '');

        const cp = {
            optionIds, title: this.formatTitle(), subtitle: this.formatSubtitle(), price,
        };
        seBookingDetails(get(this.props, 'booking.cart', {}), cp, get(this.props, 'product.title', '').toLowerCase(), get(this.props, 'client.first_time_booker', null), 'booking_23_variant', size(cps), this.getGender(), loggedIn, market, "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));

        cps = [cp];

        this.props.setLoaderFlag(false);
        this.createEventGuestCart();
    }

    showRelevantPrices() {
        const { price, quantity, raw_price } = this.state;
        const loggedIn = get(this.props, 'client.loggedIn', false);
        const total = raw_price * quantity;
        const currSymbol = get(this.props, 'booking.cart.currency_symbol', '') || toNumber(head(price)) ? head(price) : '$';
        if (total === 0) {
            return '';
        }
        if (loggedIn) {
            const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false) || get(this.props, 'client.soothe_pass.soothe_pass_subscribed', false);
            if ((!isSoothePassSubscribed) && price) {
                return ` — ${currSymbol}${total}`;
            }
        } else if (price) {
            return ` — ${currSymbol}${total}`;
        }
        return '';
    }

    showRelevantPricesOldWay() {
        const {
            price, raw_price,
        } = this.state;
        const currSymbol = get(this.props, 'booking.cart.currency_symbol', '') || toNumber(head(price)) ? head(price) : '$';
        return { content: raw_price ? `${currSymbol}${raw_price}` : "", direction: 'left' };
    }
    updateCTAStatus(skipp = false) {
        const prdId = get(this.props, 'product.id', '');
        const { optionIds, endTimeIsInvalid, guest_no, number_of_providers, } = this.state;
        this.setState({ ctaDisabled: false }, () => {
            if (prdId) {
                if (endTimeIsInvalid || !guest_no || isEmpty(optionIds) || (this.isMassageType() && size(optionIds) < 3) || (!this.isMassageType() && size(optionIds) < 1)) {
                    this.setState({ ctaDisabled: true });
                    return false;
                }
                if (skipp) {
                    calculateEventProviders({
                        session_date: get(this.props, 'fieldsHolder.listingPageStorage.cart.session_date', '') || get(this.props, 'booking.cart.date.utc', ''),
                        session_time: this.state.start_time,
                        session_end_time: this.state.end_time,
                        number_of_guests: this.state.guest_no,
                        option_id: this.isMassageType() ? Number(this.state.option_id) : Number(get(this.state, 'optionIds.0', '')),
                    }, (res) => {
                        this.setState({ number_of_providers: get(res, 'data.number_of_providers', 0), break_time: get(res, 'data.break_time', 0), recommendation_recieved: true })

                        if (!get(res, 'data.number_of_providers', 0)) {
                            this.setState({ ctaDisabled: true });
                        } else {
                            this.setState({ ctaDisabled: false });
                        }
                    }, (err) => {
                        this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage), recommendation_recieved: false })
                    });
                } else {
                    this.setState({ ctaDisabled: false });
                }
            }
            if (!skipp) {
                this.submitOptionsAndNext(true);
            }
        });
    }

    updateOptionsByCartProduct(optionId, allowedValues, required, title, field) {
        if (optionId) {
            const { optionIds } = this.state;
            const newArray = difference(optionIds, allowedValues);
            newArray.push(optionId);
            this.setState({ optionIds: newArray, [field]: title }, () => {
                if (required) {
                    this.updateCTAStatus(true);
                }
            });
        }
    }
    isValidTime() {
        let { end_time, start_time } = this.state;
        if (!end_time || !start_time) return "";
        let tempStart = moment(`${this.state.session_date} ${start_time}`, "YYYY-MM-DD h:mm A");
        let tempEnd = moment(`${this.state.session_date} ${end_time}`, "YYYY-MM-DD h:mm A");
        let diff = tempEnd?.diff(tempStart, 'hours');
        this.setState({ duration: tempEnd.isBefore(tempStart) ? -1 : diff }, () => this.updateCTAStatus(true));
        return diff >= 3 && diff <= 8 ? "" : "invalid";
    }
    relevantBody() {
        const loggedIn = get(this.props, 'client.loggedIn', false);
        const { optionIds, recommendation_recieved, break_time, number_of_providers, number_of_guests,
            unavailablePros, unavailableProsMessage, guest_no, start_time, end_time, endTimeIsInvalid, duration } = this.state;
        const pOptions = get(this.props, 'product.product_options', []);
        const prd = find(pOptions, (p) => (p.display_type === 'bubble' && p.slug === 'massage_event_length'));
        const options = get(prd, 'options', []);
        const slotOptions = map(options, (el) => ({
            label: el.title, value: el.id
        }))
        const prd2 = find(pOptions, (p) => (p.display_type === 'bubble' && p.slug === 'massage_event_gender'));
        const options2 = get(prd2, 'options', []);
        const slotOptions2 = map(options2, (el) => ({
            label: el.title, value: el.id
        }))
        const date = get(this.props, 'fieldsHolder.listingPageStorage.cart.session_date', '') || moment(get(this.props, 'booking.cart.date.utc', '')).format("MM/DD/YYYY");
        if (unavailablePros) {
            const [first, rest] = unavailableProsMessage.split('on', 2);
            const [part1, part2] = rest.split('from', 2);
            return (<div className='d-flex-only align-items-center'>
                {/* TODO recheck vertical alignment */}
                <div className="mb-100">
                    <div className="size-16-24 contentPrimary mb-32 centered mt-24">
                        <div className='mb-16'>{first}{' '}<br />on{' '}
                            <span className="font-weight-bold">{part1}</span>{' '}from{' '}
                            <span className='font-weight-bold'>{part2}</span>
                        </div>
                        <div>Please select a different service or try another date and/or time.</div>
                    </div>
                    <div className='d-flex-only justify-content-centered'>
                        <div>
                            <button className='btn md-cta-quaternary mr-10'
                                onClick={() => {
                                    this.setState({ unavailablePros: false, unavailableProsMessage: '' }, () => {
                                        this.props.closeDrawer();
                                    })

                                }}>Change Service</button>
                        </div>
                        <div>
                            <button className='btn md-cta-tertiary p-10-12-imp'
                                onClick={() => {
                                    this.setState({ unavailablePros: false, unavailableProsMessage: '' }, () => {
                                        const parsedParams = queryString.parse(window.location.search);
                                        window.location.href = `/events-booking?event-organizer=${parsedParams['event-organizer']}`;
                                    })
                                }}
                            >Change Date/Time</button>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
        return <div className='mb-100'>
            <div className="br-b-opaque ptb-20 d-flex-only justify-content-spaced align-items-center">
                <div>
                    <div className="size-16-20 color-black medium-font mb-4">Guest Count</div>
                    <div className='size-14-20 contentSecondary'>Guests expected to receive a service.</div>
                </div>
                <div>
                    <input type="number" className="form-control w-120-imp generic-input-style-white"
                        defaultValue={guest_no || ""}
                        onChange={(e) => this.setState({ guest_no: Number(e.target.value) }, () => this.updateCTAStatus(true))}
                        onWheel={(e) => e.target.blur()}
                        placeholder='Enter'
                    />
                </div>
            </div>

            <div className="br-b-opaque ptb-20 d-flex-only justify-content-spaced align-items-center">
                <div>
                    <div className="size-16-20 color-black medium-font mb-4">Event Start Time</div>
                    <div className='size-14-20 contentSecondary'>This does not include set up.</div>
                </div>
                <div className="gray-select-style">
                    <SelectTimeWrapper
                        customClassName="generic-selector-style-white w-120-imp"
                        date={date}
                        time={start_time}
                        minTime="8:00 AM"
                        maxTime="10:00 PM"
                        onChange={(start_time) => {
                            this.setState({ start_time }, () => {
                                this.setState({ endTimeIsInvalid: this.isValidTime() ? "invalid" : "" })
                            })
                        }}
                        placeholder="Select"
                    />
                </div>
            </div>

            <div className="br-b-opaque ptb-20 d-flex-only justify-content-spaced align-items-center">
                <div>
                    <div className="size-16-20 color-black medium-font mb-4">
                        Event End Time
                    </div>
                    <div className='size-14-20 contentSecondary'>
                        Minimum event duration is 3 hours.<br />
                        This does not include clean up.
                    </div>
                </div>
                <div className='w-120-imp'>
                    <SelectTimeWrapper
                        customClassName={`generic-selector-style-white ${endTimeIsInvalid} w-120-imp`}
                        date={date}
                        time={end_time}
                        minTime="11:00 AM"
                        maxTime="10:00 PM"
                        onChange={(end_time) => {
                            this.setState({ end_time }, () => {
                                this.setState({ endTimeIsInvalid: this.isValidTime() ? "invalid" : "" })
                            })
                        }}
                        disabled={!start_time}
                        placeholder="Select"
                    />
                    {duration < 0 ? <div className='invalid-msg size-14-20'>End time must be after start time.</div> : null}
                    {duration < 3 && duration >= 0 ? <div className='invalid-msg size-14-20'>Minimum event duration is 3 hours.</div> : null}
                    {duration > 8 ? <div className='invalid-msg size-14-20'>Maximum event duration is 8 hours.</div> : null}
                </div>
            </div>
            {this.isMassageType() ? <>
                <div className="br-b-opaque ptb-20 d-flex-only justify-content-spaced align-items-center">
                    <div>
                        <div className="size-16-20 color-black medium-font mb-4">Session Length</div>
                        <div className='size-14-20 contentSecondary'>Individual services within the event.</div>
                    </div>
                    <div>
                        <Select
                            className="generic-selector-style-white w-120-imp"
                            classNamePrefix="select"
                            placeholder="Select"
                            value={find(slotOptions, (el) => includes(optionIds, el.value))}
                            options={slotOptions}
                            isSearchable={false}
                            onChange={(val) => {
                                this.updateOptionsByCartProduct(Number(val.value), slotOptions.map((el) => el.value), true, val.label, 'session')
                                this.setState({ option_id: Number(val.value) })
                            }}
                        />
                    </div>
                </div>
                <div className="br-b-opaque ptb-20 d-flex-only justify-content-spaced align-items-center">
                    <div>
                        <div className="size-16-20 color-black medium-font mb-4">Provider Gender</div>
                    </div>
                    <div>
                        <Select
                            className="generic-selector-style-white w-120-imp"
                            classNamePrefix="select"
                            placeholder="Select"
                            value={find(slotOptions2, (el) => includes(optionIds, el.value))}
                            options={slotOptions2}
                            isSearchable={false}
                            onChange={(val) =>
                                this.updateOptionsByCartProduct(Number(val.value), slotOptions2.map((el) => el.value), true, val.label, 'gender')}
                        />
                    </div>
                </div>
            </> : null}
            {recommendation_recieved && !loggedIn ? <div>
                <div className='size-14-20 contentSecondary mt-24 mb-24'>Based on your selections:</div>
                <div className='size-14-16 color-black medium-font mb-24'>{number_of_providers} Providers required</div>
                <div className='size-14-20 contentSecondary mb-24'>
                    To meet the requirements selected above,  {number_of_providers} Providers are required to service {number_of_guests} guests in {duration} hours.
                    <br /><br />
                    For a {duration} hour event, each Provider is entitled to a {break_time} min paid break.
                </div>
            </div> : null}
            {recommendation_recieved && loggedIn ? <div>
                <div className='size-14-20 contentSecondary mb-24'>Based on your selections:</div>
                <div className="ptb-20 d-flex-only justify-content-spaced align-items-center">
                    <div className="size-16-20 color-black medium-font mb-4">Providers needed</div>
                    <div>
                        <input type="number" className="form-control w-120-imp generic-input-style-white"
                            value={number_of_providers || ""}
                            onChange={(e) => this.setState({ number_of_providers: Number(e.target.value) })}
                            onWheel={(e) => e.target.blur()}
                            placeholder='Enter'
                        />
                    </div>
                </div>
            </div>
                : null}
        </div>
    }
    requestHelpBtn() {
        const loggedIn = get(this.props, 'client.loggedIn', false);
        return (!loggedIn ? <div className='bg-primary fxd-btm-90 ptb-16'>
            <div className='d-flex-only justify-content-centered align-items-center bg-secondary br-rd-8 p-16'>
                <div>
                    <div className='size-14-16 color-black medium-font mb-4'>Have questions?</div>
                    <div className='size-14-20 contentSecondary'>Request help from a Soothe representative.</div>
                </div>
                <div>
                    <button className='btn transparent-button BluePrimary medium-font p-10-12-imp'
                        onClick={() => {
                            this.setState({ showRequestRepModal: true })
                        }}>Request Help</button>
                </div>
            </div>
        </div> : null)
    }
    listAppointmentsByClient() {
        const option = get(this.props, 'booking.currentOption', {});
        return (
            <div className="p-16">
                <div className="font-weight-bold size-24-32 contentPrimary mb-4">{get(option, 'title', '')}</div>
                <div className="size-14-20 contentSecondary mb-16">{get(option, 'long_description', '')}</div>

                {this.relevantBody()}

                {this.requestHelpBtn()}
            </div>
        );
    }

    continueFlowWithRepresentative() {
        this.setState({ loading: true });
        let { emailIsValid, phoneIsValid } = this.state;
        let listingPageStorage = get(this.props, 'fieldsHolder.listingPageStorage', {});
        let { occasion, kind, company, session_date } = listingPageStorage?.cart;
        let payload = pick(this.state, ["first_name", "last_name", "email", "phone"]);
        if (!payload.first_name || !payload.last_name || !payload.email || !payload.phone || !emailIsValid || !phoneIsValid) {
            this.setState({ loading: false, showErrorModal: true, error: "Please fill out all fields." });
            return;
        }
        contactRepresentative(
            {
                ...payload,
                occasion, kind, company,
                date: moment(session_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                ...listingPageStorage?.address
            },
            (_response) => {
                this.setState({ loading: false, showSuccessModal: true, showRequestRepModal: false });
            },
            (error) => {
                const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
                this.setState({ loading: false, showErrorModal: true, error: message });
            }
        );
    }

    thankYouView() {
        return (
            <LatestModalDesign isOpen={this.state.showSuccessModal}
                customIcon={<Close style={{ fontSize: '14px' }} />}
                hideCancel
                apply={() => this.setState({ showSuccessModal: false })}
                applyBtnCopy="Close"
                footerExtraClass="txt-center child-w-50"
            >
                <div className='centered'>
                    <div className='centered'><BlueCheckLottie /></div>
                    <div className='size-44-52 medium-font color-black mb-16'>Event Inquiry Received</div>
                    <p className='size-18-28 color-black mb-32'>A Soothe representative will be in touch with you in 1-2 business days. Feel free to continue browsing our services in the meantime. </p>
                </div>
            </LatestModalDesign>
        )
    }
    requestRepModal() {
        const { first_name, last_name, email, phone, emailIsValid, phoneIsValid } = this.state;

        return (
            <LatestModalDesign isOpen={this.state.showRequestRepModal}
                title="Event Inquiry"
                subtitle="Please enter the following details and a Soothe representative will be in touch with you in 1-2 business days."
                back={() => this.setState({ showRequestRepModal: false })}
                customIcon={<Close />}
                hideCancel
                apply={this.continueFlowWithRepresentative}
                applyBtnCopy="Submit"
                footerExtraClass="txt-center child-w-50"
            >
                <form onSubmit={(e) => { e.preventDefault(); }}>
                    <div className='row'>
                        <div className="col-xs-6 mb-24">
                            <div className="mb-10 medium-font color-black size-14-16">First Name*</div>
                            <input
                                defaultValue={first_name}
                                type="text"
                                className="form-control generic-input-style-white"
                                onChange={(e) => this.setState({ first_name: e.target.value })}
                            />
                        </div>
                        <div className="col-xs-6 mb-24">
                            <div className="mb-10 medium-font color-black size-14-16">Last Name*</div>
                            <input
                                defaultValue={last_name}
                                type="text"
                                className="form-control generic-input-style-white"
                                onChange={(e) => this.setState({ last_name: e.target.value })}
                            />
                        </div>
                        <div className="col-xs-6 mb-24">
                            <div className="mb-10 medium-font color-black size-14-16">Email*</div>
                            <EmailInput className={`form-control generic-input-style-white ${!emailIsValid && email ? 'invalid' : ''}`}
                                email={email}
                                onEmailChange={({ email, isValid }) => this.setState({
                                    email,
                                    emailIsValid: isValid
                                }, this.validateForm)} />
                        </div>
                        <div className="col-xs-6 mb-24">
                            <div className="mb-10 medium-font color-black size-14-16">Phone Number*</div>
                            <div>
                                <PhoneInput
                                    showLable={false}
                                    onPhoneNumberChange={({ number, isValidNumber }) => {
                                        this.setState({ phone: number, phoneIsValid: isValidNumber }, this.validateForm);
                                    }}
                                    customStyle="w-100-perc"
                                    className={`intl-tel-input generic-input-style-white size-14-20 contentPrimary z-index-0 w-100-perc ${!phoneIsValid && phone ? 'invalid' : ''}`}
                                    phone={phone}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </LatestModalDesign>
        )
    }

    errorHandler(error) {
        this.props.setLoaderFlag(false);
        this.setState({ submitted: false, showErrorModal: true, error });
    }

    errorModal() {
        return (
            <ErrorModal
                isOpen={this.state.showErrorModal}
                close={() => { this.setState({ showErrorModal: false, error: null }); }}
            >
                <p>{this.state.error}</p>
            </ErrorModal>
        );
    }
    render() {
        const { ctaDisabled, unavailablePros, loading } = this.state;

        return (<>
            <div className="width-485 sm-w-100">
                <div className="max-h-full-90 overflow-y-scroll">
                    <div className="bg-cover height-320" style={{ backgroundImage: `url(${get(find(get(get(this.props, 'booking.currentOption', {}), 'images', []), (im) => (im.kind === 'card_display_image')), 'web_url')})` }}>
                        <button
                            type="button"
                            className="btn contentPrimary br-rd-48 fixed-btn-shadow-simple bg-primary abs-top m-12 d-flex-only align-items-center w-h-48 justify-content-centered"
                            onClick={this.props.closeDrawer}
                        >
                            <Close />
                        </button>
                    </div>
                    {this.listAppointmentsByClient()}
                </div>
                <CTAButton
                    text="Add to cart"
                    disabled={ctaDisabled || unavailablePros}
                    addon={this.showRelevantPricesOldWay()}
                    submitted={this.state.submitted}
                    action={this.submitOptionsAndNext}
                    forceLeft
                    additionalClass="width-auto"
                    additionalWrapperClass="justify-content-spaced sm-display-flex fxd-btm-right width-485-imp"
                    relevantId="continueButton"
                />
            </div>
            {this.requestRepModal()}
            {this.thankYouView()}
            {this.errorModal()}
            <LoadingOverlay showLoader={loading} />
        </>);
    }
}

const mapStateToProps = (state) => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow,
    product: state.booking?.product,
    anonymousCart: state.anonymousCart,
    fieldsHolder: state.fieldsHolder,
});


export default connect(mapStateToProps, {
    setCart,
    setBookingFlowPreviousStep,
    setAvailableTherapists,
    setAvailableTherapistsWithTimeSlots,
    setAnonymousCart,
    setField,
})(ModalityStepForEvent);