/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import {
  forEach, intersection, isEmpty, keys,
} from 'lodash';
import loupeIcon from '../../Assets/Images/Loupe_icon.png';

const keysConfig = {
  postal_code: {
    fieldName: 'zipCode',
    valueToRead: 'short_name',
  },
  country: {
    fieldName: 'country',
    valueToRead: 'short_name',
  },
  administrative_area_level_1: {
    fieldName: 'state',
    valueToRead: 'short_name',
  },
  administrative_area_level_2: {
    fieldName: 'county',
    valueToRead: 'long_name',
  },
  administrative_area_level_4: {
    fieldName: 'township',
    valueToRead: 'long_name',
  },
  locality: {
    fieldName: 'city',
    valueToRead: 'long_name',
  },
};

const addressAdditionalDetails = ['street_number', 'neighborhood', 'route', 'sublocality'];

const cKeys = keys(keysConfig);

class PlaceAutoCompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clearable: false,
      hasStreetNumber: false,
    };

    this.handlePlaceSelection = this.handlePlaceSelection.bind(this);
    this.mapOverDetails = this.mapOverDetails.bind(this);
  }

  formatStr(txt, separator = '', addedStr = '') {
    if (txt) {
      return txt + separator + addedStr;
    }
    return addedStr;
  }

  mapOverDetails(address_components) {
    const formattedAddress = {
      name: '',
      addressLine1: '',
    };
    forEach(address_components, (comp) => {
      const currTypes = comp.types;
      if (!isEmpty(intersection(currTypes, cKeys))) {
        const conf = keysConfig[currTypes[0]];
        formattedAddress[conf.fieldName] = comp[conf.valueToRead];
      } else if (!isEmpty(intersection(currTypes, addressAdditionalDetails))) {
        const isStreetInfo = intersection(currTypes, ['street_number', 'route']);
        const skipSeparator = !isEmpty(isStreetInfo);
        const separationChar = skipSeparator ? ' ' : ', ';
        if (isStreetInfo) {
          this.setState({ hasStreetNumber: true });
        }
        formattedAddress.name = this.formatStr(
          formattedAddress.name,
          separationChar,
          comp.short_name,
        );
        formattedAddress.addressLine1 = this.formatStr(
          formattedAddress.addressLine1,
          separationChar,
          comp.long_name,
        );
      }
    });
    if (this.props.inCaseOfError && !this.state.hasStreetNumber) {
      this.props.inCaseOfError();
    } else { this.props.handleAddress(formattedAddress); }
  }

  handlePlaceSelection(place) {
    this.setState({ clearable: Boolean(!isEmpty(place)) });
    const address_components = place && place.address_components;
    if (!isEmpty(address_components)) {
      this.mapOverDetails(address_components);
    } else if (place && place.name) {
      this.props.handleAddress({ addressLine1: place.name });
    }
  }

  inputWithIcon() {
    const { uniqueId } = this.props;
    const { hideIcon } = this.props;
    return (
      <>
        <Autocomplete
          id={uniqueId}
          placeholder={this.props.placeholder || ''}
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
          className={this.props.inputStylingClass}
          options={{
            fields: ['address_components'],
            types: ['geocode', 'establishment'],
          }}
          onPlaceSelected={this.handlePlaceSelection}
          autocomplete="off"
        />
        {hideIcon ? null : (
          <img
            className="input-icon"
            src={loupeIcon}
            alt="Loupe-icon"
          />
        )}
      </>
    );
  }

  render() {
    return (
      <div className="p-relative">
        {this.inputWithIcon()}
      </div>
    );
  }
}

export default PlaceAutoCompleteInput;
