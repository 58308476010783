/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios';
import { isEmpty, replace } from 'lodash';
import { ACCEPT_LANGUAGE_HEADER, API_ROOT, HOST_ROOT } from '../../apiConfig';
import { ROUTES } from '../../constants';
import { hashAffiliateUtms } from './WebAnalytics';

function rejectFunc(err, errorHandler) {
  if (errorHandler) {
    errorHandler(err);
  } else {
    console.log(err);
  }
}
function resolveFunc(response, cb, errCb) {
  const { result, success, session_id } = response.data;
  if ((result || success || session_id) && cb) {
    cb(response);
  } else {
    rejectFunc({ response }, errCb);
  }
}

export function getChatsOfCurrentUser(callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/chats`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getClient(callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/clients`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getClientById(id, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/clients/${id}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function updateClientById(id, client, callBackFunc, errorHandler) {
  axios.put(`${API_ROOT}/clients/${id}`, {
    client,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getSessionParams(user_id, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/clients/${user_id}/sift_science_params`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getEmail(email, callBackFunc, errorHandler) {
  const frmtdEmail = encodeURIComponent(replace(email, /\s/g, ''));
  axios.get(`${API_ROOT}/users/email_exists?email=${frmtdEmail}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getPhoneNumber(mobile_number, callBackFunc, errorHandler) {
  const frmtdMobile = replace(mobile_number, /\+/g, '%2B');
  axios.get(`${API_ROOT}/clients/mobile_number_exists?mobile_number=${frmtdMobile}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function userSignup(headers, user, callBackFunc, errorHandler) {
  axios.post(`${HOST_ROOT}/users`, {
    user,
    react_flow: true,
    attribution: hashAffiliateUtms(),
  }, { headers })
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function userSignin(headers, user, callBackFunc, errorHandler) {
  axios.post(`${HOST_ROOT}${ROUTES.oldUserSignin}`, {
    user, react_flow: true,
  }, { headers })
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function fbSignup(user, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/users/facebook`, {
    user,
    react_flow: true,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function fbSignin(user, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/sessions/facebook`, {
    user,
    react_flow: true,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function gglSignup(user, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/users/google`, {
    user,
    react_flow: true,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function gglSignin(user, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/sessions/google`, {
    user,
    react_flow: true,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getAnonymousUser(callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/anonymous_user`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function sendAttributions(attribution, user_id, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/attributions`, {
    attribution,
    user_id,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function verifyPhoneNumber(user_id, inquiry_id, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/users/${user_id}/verify_phone_number`, {
    inquiry: {
      id: inquiry_id,
      // inquiry, the id is returned from the Persona SDK after we do their verification process.
      // We are having the backend do a double check to verify that it's confirmed on our end.
    },
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getAddressesOfCurrentUser(userId, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/users/${userId}/addresses`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function addAddressForCurrentUser(address, callBackFunc, errorHandler) {
  axios.post(`${HOST_ROOT}/addresses`, {
    address,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function updateAddressOfCurrentUser(addressId, address, callBackFunc, errorHandler) {
  axios.put(`${API_ROOT}/addresses/${addressId}`, {
    address,
    // "address": {
    //     "name": "Test Label",
    //     "city": "Los Angeles",
    //     "address_line_1": "7998 Santa Monica Blvd",
    //     "state": "CA",
    //     "country": "US",
    //     "parking_info": "Some useful info1",
    //     "zip_code": "90046"
    // default : 1 TODO can be added as a separate action setDefault
    //   }
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function deleteAddressOfCurrentUser(addressId, callBackFunc, errorHandler) {
  axios.delete(`${API_ROOT}/addresses/${addressId}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
// TODO maybe use clients instead of users
// if show means get single address record.. what is the difference between users/ and clients/
export function getCreditCardsOfUser(userId, callBackFunc, errorHandler) {
  if (userId) {
    axios.get(
      `${API_ROOT}/users/${userId}/credit_cards`,
      { withCredentials: true },
      ACCEPT_LANGUAGE_HEADER,
    )
      .then((response) => resolveFunc(response, callBackFunc))
      .catch((err) => rejectFunc(err, errorHandler));
  }
}
export function deleteCreditCardOfUser(creditCardId, callBackFunc, errorHandler) {
  if (creditCardId) {
    axios.delete(
      `${API_ROOT}/credit_cards/${creditCardId}`,
      { withCredentials: true },
      ACCEPT_LANGUAGE_HEADER,
    )
      .then((response) => resolveFunc(response, callBackFunc))
      .catch((err) => rejectFunc(err, errorHandler));
  }
}
export function logoutCurrentUser(callBackFunc, errorHandler) {
  axios.delete(
    `${HOST_ROOT}/users/sign_out`,
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  )
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getCartWithSalesTax(cartId, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/v7/carts/${cartId}/calculate_sales_tax`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getCartReceipt(cartId, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/v7/carts/${cartId}/cart_receipt`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getAvailableTherapistsWithTimeSlots(
  cartId,
  cartProductId,
  session_date,
  callBackFunc,
  errorHandler,
) {
  axios.get(`${API_ROOT}/v7/carts/${cartId}/cart_products/${cartProductId}/available_therapists?session_date=${session_date}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function removeProFromList(cartId, cartProductId, therapist_id, callBackFunc, errorHandler) {
  axios.delete(
    `${API_ROOT}/v7/carts/${cartId}/cart_products/${cartProductId}/remove_pro_request`,
    { data: { therapist_id } },
    { withCredentials: true },
    ACCEPT_LANGUAGE_HEADER,
  )
    .then((resp) => {
      const { result } = resp.data;
      if (result && callBackFunc) {
        callBackFunc(resp);
      }
    }).catch((err) => {
      if (errorHandler) {
        errorHandler(err);
      } else {
        console.log(err);
      }
    });
}
export function triggerResetPasswordEmail(user, callBackFunc, errorHandler) {
  // it will send an email to the user with a deep link like the following
  // https://soothe.onelink.me/9Hnv/kk9qd5s7?reset_password_token=N2x2Jt2xiZroezkcEiHx&token_expiry=2023-10-02T19:23:28-0700
  axios.post(`${API_ROOT}/users/password/send_reset_token`, {
    user,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
  // user: {
  //     "email": "test@soothe.com",      <-- not required if token is provided
  //     "reset_password_token": "TgvJtBZJ7aMvaMyBsYCs"   <-- not required if email is provided
  //   }
}
export function resetPasswordSubmission(user, callBackFunc, errorHandler) {
  axios.put(`${API_ROOT}/users/password`, {
    user,
    // : {
    //     "email": "test@soothe.com",        <-- required if token not provided
    //     "current_password": "password",    <-- required if token not provided
    //     "password": "NewPassword2@",
    //     "reset_password_token": "TgvJtBZJ7aMvaMyBsYCs"
    // <-- required if email and current_password are not provided
    //   }
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function getRecommendedPros(cartId, cartProductId, callBackFunc, errorHandler) {
  axios.get(`${API_ROOT}/v7/carts/${cartId}/cart_products/${cartProductId}/recommended_providers`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function authTokenPost(callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/auth_token`, {}, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
/**
 * One-Off Event APIs
 */
export function getProductsByParams(params, callBackFunc, errorHandler) {
  const paramStr = !params || isEmpty(params) ? "" : Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
  axios.get(`${API_ROOT}/v7/products?${paramStr}`, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function postEvent(event, callBackFunc, errorHandler) {
  // "event": {
  //            "type": "personal",
  //            "session_date": "2024-11-20",
  //            "session_time": "13:00",
  //            "address_id": 37551,
  //            "credit_cart_id": 123,
  //            "option_ids": [1, 2, 3],
  //            "no_pros": 2
  //          }
  // TODO: Add more fields as needed
  axios.post(`${API_ROOT}/v7/events`, {
    event,
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc, errorHandler))
    .catch((err) => rejectFunc(err, errorHandler));
}
export function contactRepresentative(payload, callBackFunc, errorHandler) {
  // { "first_name: "test", ##REQUIRED
  //   "last_name: "user", ##REQUIRED
  //   "email": "test_user@soothe.com", ##REQUIRED
  //   "phone": "+13104567890", ##REQUIRED
  //   "occasion": "Birthday", ##REQUIRED
  //   "start_time": "2024-11-22 15:00:00", ##REQUIRED Standard date format UTC or assume timezone from address??
  //   "end_time": "2024-11-22 18:00:00", ##REQUIRED Standard date format UTC or assume timezone from address??
  //   "address_line_1": "1800 Highland Ave" ##REQUIRED, from google API
  //   "address_line_2": "", from google API
  //   "city": "Los Angeles" ##REQUIRED, from google API
  //   "state": "CA" ##REQUIRED, from google API
  //   "zip_code": "90028" ##REQUIRED,f rom google API
  //   "country": "US" ##REQUIRED, from google API
  //   "no_pros": 3 ##REQUIRED,
  //   "guest_no": 15 ##REQUIRED,
  //   "kind": "personal" ##REQUIRED, [personal or corporate]
  //   "company": "", Name of company if corporate
  //   "date": "2024-11-22" ##REQUIRED
  //   }
  axios.post(`${API_ROOT}/v7/events/contact_me`, {
    ...payload
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}

export function initCartJson(cart_json, callBackFunc, errorHandler) {
  if (cart_json) {
    axios.post(`${API_ROOT}/guest/init_guest/init_guest_cart`,
      {
        "cart_json": JSON.stringify(cart_json)
      }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
      .then((response) => resolveFunc(response, callBackFunc))
      .catch((err) => rejectFunc(err, errorHandler));
  }
}

export function calculateEventProviders({ session_date,
  session_time,
  session_end_time,
  number_of_guests,
  option_id }, callBackFunc, errorHandler) {
  axios.post(`${API_ROOT}/guest/carts/calculate_providers_needed`, {
    cart: {
      session_date,
      session_time,
      session_end_time,
      number_of_guests,
      option_id
    }
  }, { withCredentials: true }, ACCEPT_LANGUAGE_HEADER)
    .then((response) => resolveFunc(response, callBackFunc))
    .catch((err) => rejectFunc(err, errorHandler));
}
